
import { Component, Vue } from 'vue-property-decorator'
import { SoilHumidityDetail, EchartList, TypeList } from '../../types/ecologyMonitor.d'
import * as echarts from 'echarts'

@Component({
  name: 'History'
})
export default class SoilHistory extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 29 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  private loading = false
  private dataList: Array<SoilHumidityDetail> = []
  private chartList: Array<SoilHumidityDetail> = []
  page = 1
  size = 10
  total = 0

  get deviceNumber () {
    return this.$route.params.deviceNumber as string
  }

  private echart: any
  private echartSelect = '0'
  private echartData: EchartList = {
    ph: [],
    humidity: [],
    conductivity: [],
    temperature: [],
    deviceNumber: '',
    collectTime: [],
    nitrogen: [],
    phosphorus: [],
    potassium: [],
    redox: []
  }

  private typeList: Array<TypeList> = []
  private finds: Array<string> = []

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    this.getData()
    this.getChartList()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  initTime () {
    const val = this.$dayjs().format('YYYY-MM-DD')
    const vals = this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD')
    console.log(vals)
    this.searchInfo.dateRange = [vals, val]
  }

  getData () {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.loading = true
      this.$axios.get(this.$apis.ecologyMonitor.selectSoilDataByPage, {
        deviceNumber: this.deviceNumber,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1],
        page: this.page,
        size: this.size
      }).then((res) => {
        this.total = res.soil.total || 0
        this.dataList = res.soil.list || []
        this.finds = res.finds
        // this.getEchartsData()
      }).finally(() => {
        this.loading = false
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  onSearch () {
    this.page = 1
    this.getData()
    this.getChartList()
  }

  // 图表总数据
  getChartList () {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.$axios.get(this.$apis.ecologyMonitor.selectSoilDataByPage, {
        deviceNumber: this.deviceNumber,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res) => {
        this.chartList = res.soil.list || []
        this.getEchartsData()
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  // 图表数据
  getEchartsData () {
    this.echartData = {
      ph: [],
      humidity: [],
      conductivity: [],
      temperature: [],
      deviceNumber: '',
      collectTime: [],
      nitrogen: [],
      phosphorus: [],
      potassium: [],
      redox: []
    }
    if (this.chartList.length > 0) {
      this.chartList.forEach(item => {
        this.echartData.deviceNumber = item.deviceNumber
        this.echartData.temperature.push(item.temperature)
        this.echartData.humidity.push(item.humidity)
        this.echartData.ph.push(item.ph)
        this.echartData.conductivity.push(item.conductivity)
        this.echartData.nitrogen.push(item.nitrogen)
        this.echartData.phosphorus.push(item.phosphorus)
        this.echartData.potassium.push(item.potassium)
        this.echartData.redox.push(item.redox)
        this.echartData.collectTime.push(item.collectTime)
      })
    }
    this.typeList = [
      {
        legendData: '土壤温度',
        color: 'rgba(255, 193, 23, ',
        data: this.echartData.temperature,
        unit: '℃',
        value: '0',
        label: '土壤温度曲线图',
        type: 'temperature'
      },
      {
        legendData: '土壤湿度',
        color: 'rgba(44, 142, 255, ',
        data: this.echartData.humidity,
        unit: '℃',
        value: '1',
        label: '土壤湿度曲线图',
        type: 'humidity'
      },
      {
        legendData: 'pH',
        color: 'rgba(18, 194, 194, ',
        data: this.echartData.ph,
        unit: '',
        value: '2',
        label: 'pH值曲线图',
        type: 'ph'
      },
      {
        legendData: 'EC',
        color: 'rgba(47, 194, 90, ',
        data: this.echartData.conductivity,
        unit: 'mS/cm',
        value: '3',
        label: 'EC值曲线图',
        type: 'conductivity'
      },
      {
        legendData: '氮',
        color: 'rgba(255, 193, 23, ',
        data: this.echartData.nitrogen,
        unit: 'mg/kg',
        value: '4',
        label: '氮含量曲线图',
        type: 'nitrogen'
      },
      {
        legendData: '磷',
        color: 'rgba(44, 142, 255, ',
        data: this.echartData.phosphorus,
        unit: 'mg/kg',
        value: '5',
        label: '磷含量曲线图',
        type: 'phosphorus'
      },
      {
        legendData: '钾',
        color: 'rgba(109, 212, 0, ',
        data: this.echartData.potassium,
        unit: 'mg/kg',
        value: '6',
        label: '钾含量曲线图',
        type: 'potassium'
      },
      {
        legendData: 'ORP',
        color: 'rgba(47, 194, 90, ',
        data: this.echartData.redox,
        unit: 'mV',
        value: '7',
        label: 'ORP含量曲线图',
        type: 'redox'
      }
    ]
    this.draw(Number(this.echartSelect))
  }

  // 折线图
  draw (type: number) {
    this.$nextTick(() => {
      const series: any = []
      const params = this.typeList[type]
      console.log(params)
      series.push({
        name: params.legendData,
        type: 'line',
        symbolSize: 10,
        yAxisIndex: 0,
        smooth: true,
        lineStyle: {
          width: 4,
          shadowBlur: 0,
          shadowColor: `${params.color}0.2)`,
          shadowOffsetY: 20
        },
        itemStyle: {
          normal: {
            borderWidth: 4,
            borderColor: `${params.color}1)`
          }
        },
        data: params.data
      })
      this.echart = echarts.init(this.$refs.echart)
      const option: any = {
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: 12
          }
        },
        grid: {
          top: 50,
          left: 30,
          right: 10,
          bottom: 10,
          containLabel: true
        },
        backgroundColor: 'transparent',
        legend: {
          icon: 'rect',
          itemWidth: 12,
          itemHeight: 1,
          orient: 'horizontal',
          align: 'left',
          top: 0,
          left: 0,
          itemGap: 30,
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: '#8c8c8c',
            lineHeight: 14
          }
        },
        color: `${params.color}1)`,
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          data: this.echartData.collectTime
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: `{value}${params.unit}`
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        }],
        series: series
      }
      this.echart.setOption(option, true)
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }

  changeEchart (type: string) {
    this.draw(Number(type))
  }

  // 判断展示字段
  getFind (str: string) {
    return this.finds.find((item) => item === str)
  }
}
